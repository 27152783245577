import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Footer from '../../../components/layout/Footer';
import SeoFields from '../../../components/page/SeoFields';
import HeroImage from '../../../components/page/HeroImage';
import ShortDescription from '../../../components/page/ShortDescription';
import Content from '../../../components/page/Content';
import CloudinaryImage from '../../../components/shared/CloudinaryImage';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    display: 'block',
  },
  image: { borderRadius: '0' },
  shortDescription: {
    color: theme.palette.common.darkPurple,
    maxWidth: '600px',
    padding: theme.spacing(8, 3),
    textAlign: 'center',
    '&> p': {
      fontSize: theme.typography.h3.fontSize,
    },
  },
});

function BrandPageTemplatePhilipsonSoderberg({ classes, data }) {
  const pageData = get(data, 'contentfulBrand');
  const analytics = get(pageData, 'mainTheme.analytics');

  const heroImage = get(pageData, 'heroImage');
  const brandLogoImage = get(pageData, 'brandLogoImage');
  const imageTitleText = get(brandLogoImage, 'imageTitleText');
  const altText = get(brandLogoImage, 'imageAltText');
  const publicId = get(brandLogoImage, 'cloudinaryImage.0.public_id');

  const variants = useMemo(
    () => [
      {
        transformation: 'FullWidthImage-XS-IN',
        width: 430,
      },

      {
        transformation: 'FullWidthImage-SM-IN',
        width: 768,
      },

      {
        transformation: 'FullWidthImage-MD-IN',
        width: 1024,
      },

      {
        transformation: 'FullWidthImage-LG-IN',
        width: 1440,
      },
    ],
    [],
  );

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <SeoFields pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <HeroImage pageData={pageData} data={heroImage} height={570} showTitle>
          <CloudinaryImage
            className={classes.image}
            publicId={publicId}
            variants={variants}
            alt={altText}
            title={imageTitleText}
            width="auto"
            height="auto"
          />
        </HeroImage>
        <ShortDescription pageData={pageData} className={classes.shortDescription} />
        <Content pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

BrandPageTemplatePhilipsonSoderberg.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

BrandPageTemplatePhilipsonSoderberg.defaultProps = {
  data: null,
  classes: {},
};

export default withSecondaryTheme(withStyles(styles)(BrandPageTemplatePhilipsonSoderberg));
