import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, map } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ProductCard from '../../../shared/Products/ProductCard';

import '../../Content';

const styles = theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  title: {
    margin: theme.spacing(4, 0, 3, 0),
  },
  relatedProducts: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignContent: 'center',
  },
});

function RelatedProducts({ classes, className, pageData }) {
  const { t } = useTranslation();

  const relatedProducts = get(pageData, 'relatedProducts');

  if (isEmpty(relatedProducts)) {
    return null;
  }

  return (
    <Container disableGutters className={clsx(classes.root, className)}>
      <Typography variant="h2" align="center" className={classes.title}>
        {t('RelatedProducts.title')}
      </Typography>
      <div className={classes.relatedProducts}>
        {map(relatedProducts, (relatedProduct, index) => {
          const relatedProductId = get(relatedProduct, 'id');
          const relatedProductKey = `${relatedProductId}-${index}`;
          return <ProductCard key={relatedProductKey} productData={relatedProduct} />;
        })}
      </div>
    </Container>
  );
}

RelatedProducts.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RelatedProducts.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(RelatedProducts);

export const query = graphql`
  fragment RelatedProductFragment on MagentoProduct {
    ...ContentProductFragment
  }
`;
