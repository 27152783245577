import React from 'react';
import PropTypes from 'prop-types';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import BrandPageTemplate from '../BrandPageTemplate';

function AnoraProBrandPageTemplate({ ...props }) {
  return <BrandPageTemplate {...props} />;
}

AnoraProBrandPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

AnoraProBrandPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withAuthenticationRequired(AnoraProBrandPageTemplate);
