import React from 'react';

import { graphql } from 'gatsby';

import CommonBrandPageTemplate from './BrandPageTemplate';
import AnoraProBrandPageTemplate from './anoraPro';
import PhilipsonSoderbergBrandPageTemplate from './philipson-soderberg';

import { siteId } from '../../sites';
import { siteIds } from '../../constants';

function BrandPageTemplate({ ...props }) {
  if (siteId === siteIds.AnoraPro) {
    return <AnoraProBrandPageTemplate {...props} />;
  }
  if (siteId === siteIds.PhilipsonSoderberg) {
    return <PhilipsonSoderbergBrandPageTemplate {...props} />;
  }
  return <CommonBrandPageTemplate {...props} />;
}

export const pageQuery = graphql`
  query BrandPageTemplate($pageId: String!) {
    contentfulBrand(id: { eq: $pageId }) {
      id
      contentful_id
      title
      menuTitle
      shortDescription
      fullPath
      languageCode
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      brandLogoImage {
        ...ContentImageModuleFragment
      }
      content {
        ...ContentFragment
      }
      relatedProducts {
        ...RelatedProductFragment
      }
      relatedArticlePages {
        ...RelatedArticlePageFragment
      }
      relatedRecipePages {
        ...RelatedRecipePageFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default BrandPageTemplate;
